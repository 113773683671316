<template>
    <v-container>
        <v-card class="pa-lg-7">
            <v-form
                ref="form"
            >
                <v-card-title>Вход </v-card-title>
                <v-card-text>
                    <v-text-field
                        v-model="email"
                        type="email"
                        label="E-mail"
                        hint="Укажите электронный адрес"
                    ></v-text-field>
                    <v-text-field
                        v-model="password"
                        :append-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
                        :type="show ? 'text' : 'password'"
                        label="Пароль"
                        @click:append="show = !show"
                        @keyup.enter="login"
                    ></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" large class="px-12 mb-7 ml-2" @click="login">Войти</v-btn>
                    <div class="px-3 mb-7">или <router-link to="reg">перейти к регистрации</router-link></div>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-form>
        </v-card>
        <v-overlay :value="overlay" style="z-index:1100">
            <div>
                <v-alert v-if="!errorMessage" type="error" >
                    Неверный логин или e-mail
                </v-alert>
                <v-alert v-else type="error" >
                    {{errorMessage}}
                </v-alert>
            </div>
            <div @click="overlay = false" style="position: fixed; top:0; bottom: 0; left: 0; right:0; background-color: rgba(255,255,255,0); opacity: 0.1"></div>
        </v-overlay>
        <v-overlay :value="progress" style="z-index:1100">
            <div class="text-center">
                <v-progress-circular
                    indeterminate
                    color="primary"
                    style="z-index:1001"
                ></v-progress-circular>
            </div>
        </v-overlay>
    </v-container>
</template>

<style>
    .login {
        display: flex;
        flex-direction: column;
        width: 300px;
        padding: 10px;
    }
</style>

<script>
    import {AUTH_REQUEST} from "../store/actions/auth";

    export default {
        name: "login",
        data() {
            return {
                email: "",
                password: "",
                show: false,
                progress: false,
                overlay: false,
                errorMessage: ''
            };
        },
        methods: {
            login: function() {
                let obj = this;
                obj.progress = true;
                const { email, password } = this;
                this.$store.dispatch(AUTH_REQUEST, { email, password }).then((resp) => {
                    if (resp.redirect) {
                        window.location.href = resp.url;
                    }
                    else {
                        window.location.href = "/";
                    }
                }).catch((err) => {
                    if (err.response.status === 401) {
                        obj.overlay = true;
                    }
                    obj.progress = false;
                });
            }
        },
        mounted() {
        }
    };
</script>
